@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap);
body {
  font-family: 'Josefin Sans', sans-serif;
}

.bg-pattern {
  background-image: url(/static/media/bg-image-1.0fe77d62.png);
}

.navbar.active {
  background-color: #1680b4 !important;
}

a {
  color: #ddd;
}

a:hover {
  color: #17a2b8;
  opacity: 0.8;
  text-decoration: none;
}

a:focus,
a.active {
  color: #17a2b8;
  text-decoration: none;
}

.form-check-label {
  font-size: 12px;
}

.btn-bt-box-1 {
  background-color: #eee;
  position: relative;
}

.btn-bt-box-1:focus {
  background-color: #fff;
  box-shadow: 0 0 0 0.05rem #666;
  transition: all 0.3s ease;
}

.btn-bt-box-1:hover .bt-box-1__item {
  opacity: 0.8;
  transition: all 0.3s ease;
}

.btn-bt-box-1:focus .bt-box-1__item {
  opacity: 1;
  transition: all 0.3s ease;
}

.bt-box-1__item {
  opacity: 0;
  display: flex;
  position: absolute;
  top: 15px;
  right: 15px;
}

.avatar-container {
  display: flex;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  box-shadow: 0 0 0.1rem #000;
}

.avatar-container img {
  border-radius: 50px;
  object-fit: cover;
}

.avatar-container input {
  opacity: 0;
  position: absolute;
  width: 100px;
  height: 100px;
  cursor: pointer;
}

@media only screen and (max-width: 765px) {
  .navbar-collapse {
    transition: all 1s ease;
    position: absolute;
    background-color: #00000070;
    z-index: 999;
    top: 75px;
    left: 0;
    height: 100vh;
    width: 100%;
  }

  .vh-100-none-on-small {
    padding: 70px 0;
    height: unset !important;
  }
}

.react-notification-container {
  bottom: 50px;
  display: block;
  height: auto;
  min-width: 420px;
  right: 35px;
  position: fixed;
  z-index: 99998;
}

.react-notification-container .react-notification:not(:last-of-type) {
  margin-bottom: 14px;
}

.react-notification {
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  cursor: pointer;
  display: block;
  height: auto;
  padding: 8px 14px;
  z-index: 99999;
}

.react-notification__title {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 6px;
}

.react-notification__content {
  color: #666;
  font-size: 14px;
  font-weight: light;
}

.react-notification--success {
  border-left: 4px solid greenyellow;
}

.react-notification--error {
  border-left: 4px solid tomato;
}

